<template>
    <div>
        <DeriveExcelVue ></DeriveExcelVue>
    </div>
</template>
<script>
import DeriveExcelVue from '../upfile/DeriveExcel.vue';
export default{
    components:{
        DeriveExcelVue
    },
    data(){
     return {
        
     }
    }
}
</script>